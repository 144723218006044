<template>
  <div>
    <vue-apex-charts
      type="donut"
      height="300"
      class="my-1"
      :options="chartOptions"
      :series="series"
    />

    <div
      v-for="(data,index) in labelsData"
      :key="index"
      class="d-flex justify-content-between"
    >
      <div class="series-info d-flex align-items-center">
        <FeatherIcon
          icon="CheckCircleIcon"
          :style="'color:' + colorsData[index]"
        />
        <span class="font-weight-bolder ml-75 mr-25">{{ data }}</span>
      </div>
      <div>
        <span>%{{ seriesData[index] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DonutChart',
  components: {
    VueApexCharts,
  },
  props: {
    seriesData: {
      type: Array,
      required: true,
    },
    labelsData: {
      type: Array,
      required: true,
    },
    colorsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {},
      series: [25, 50, 45],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: [],
      },
    }
  },
  created() {
    this.setLabels()
    this.setColors()
  },
  methods: {
    setLabels() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: this.labelsData,
          chartInfo: this.labelsData,
        },
      }
      this.series = this.seriesData
    },
    setColors() {
      if (this.colorsData) {
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            colors: this.colorsData,
          },
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
