<template>
  <div v-if="bma.length > 0">
    <b-row class="match-height d-grid">
      <b-col class="d-flex align-items-center">
        <bma-card
          :title="bma[5].title"
          :color="bma[5].color"
          :score="bma[5].score"
        />
      </b-col>
      <b-col>
        <div>
          <bma-card
            :title="bma[6].title"
            :color="bma[6].color"
            :score="bma[6].score"
          />
        </div>
        <div>
          <bma-card
            :title="bma[7].title"
            :color="bma[7].color"
            :score="bma[7].score"
          />
        </div>
      </b-col>
      <b-col>
        <bma-card
          :title="bma[0].title"
          :color="bma[0].color"
          :score="bma[0].score"
        />
      </b-col>
      <b-col>
        <div>
          <bma-card
            :title="bma[2].title"
            :color="bma[2].color"
            :score="bma[2].score"
          />
        </div>
        <div>
          <bma-card
            :title="bma[3].title"
            :color="bma[3].color"
            :score="bma[3].score"
          />
        </div>
      </b-col>
      <b-col>
        <bma-card
          :title="bma[1].title"
          :color="bma[1].color"
          :score="bma[1].score"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bma-card
          :title="bma[8].title"
          :color="bma[8].color"
          :score="bma[8].score"
        />
      </b-col>
      <b-col class="text-center">
        <bma-card
          :title="bma[4].title"
          :color="bma[4].color"
          :score="bma[4].score"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import BmaCard from '@/views/App/Reports/components/BmaCard.vue'

export default {
  name: 'BmaContainer',
  components: {
    BRow, BCol, BmaCard,
  },
  props: {
    bma: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style scoped>
.d-grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

@media (max-width: 992px) {
  .d-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

</style>
