<template>
  <div>
    <b-row>
      <b-col cols="12">
        <route-buttons />
      </b-col>
      <b-col cols="12">
        <b-card class="text-center">
          <h4>{{ investorSummary.startup.title }}</h4>
          <b-badge
            v-for="(activityArea,key) in investorSummary.startup.activity_area"
            :key="key"
            variant="primary"
            class="mr-1"
          >
            {{ activityArea }}
          </b-badge>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card :title="$t('Pazar Analizi')">
          <market-graphic />
        </b-card>
      </b-col>
      <b-col
        v-if="businessModelAnalysis.length > 0"
        cols="12"
      >
        <bma-container :bma="businessModelAnalysis" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="6">
        <b-card :title="$t('Teknoloji Hazırlık Seviyesi')">
          <technology-graphic />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card :title="$t('Yatırım Dağıtım Oranları')">
          <donut-chart
            v-if="startupReport.chartData.seriesData.length > 0"
            :series-data="startupReport.chartData.seriesData"
            :labels-data="startupReport.chartData.labels"
            :colors-data="startupReport.chartData.colors"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card :title="$t('Finansal Performans')">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>{{ $t('Tahmini Şirket Değeri') }}</span>
              <span>{{ investorSummary.financial_performance.company_value | toCurrency }} ₺</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>{{ $t('Yatırım Tutarı') }}</span>
              <span>{{ investorSummary.financial_performance.investment_amount | toCurrency }} ₺</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>{{ $t('Hisse Oranı') }}</span>
              <span>{{ investorSummary.financial_performance.share_ratio }} %</span>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
    <comment :comment-data="investorSummary.comment" />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import marketGraphic from '@/views/App/Reports/components/market_analysis/Market_graphic.vue'
import technologyGraphic from '@/views/App/Reports/components/technology_readiness/Technology_graphic.vue'
import donutChart from '@/views/App/Reports/charts/Donut_chart.vue'
import routeButtons from '@/views/App/Reports/components/Route_buttons.vue'
import Comment from '@/views/App/Reports/components/Comment.vue'
import BmaContainer from '@/views/App/Reports/InvestorSummaryReport/BmaContainer.vue'

export default {
  name: 'InvestorSummaryReport',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    marketGraphic,
    technologyGraphic,
    donutChart,
    routeButtons,
    Comment,
    BmaContainer,
  },
  computed: {
    startupReport() {
      return this.$store.getters['startupReport/getStartupReport']
    },
    businessModelAnalysis() {
      return this.$store.getters['businessModelAnalysisReport/getBusinessModelAnalysis']
    },
    investorSummary() {
      return this.$store.getters['investorSummary/getInvestorSummary']
    },
  },
  created() {
    this.getInvestorSummary()
    this.getStartupReport()
    this.getMarketAnalysis()
    this.getBusinessModelAnalysis()
    this.getTechnologyReadiness()
  },
  methods: {
    getStartupReport() {
      this.$store.dispatch('startupReport/startupReport', this.$route.params.id)
    },
    getMarketAnalysis() {
      this.$store.dispatch('marketAnalysisReport/marketAnalysis', this.$route.params.id)
    },
    getBusinessModelAnalysis() {
      this.$store.dispatch('businessModelAnalysisReport/businessModelAnalysis', this.$route.params.id)
    },
    getTechnologyReadiness() {
      this.$store.dispatch('technologyReadiness/technologyReadiness', this.$route.params.id)
    },
    getInvestorSummary() {
      this.$store.dispatch('investorSummary/investorSummary', this.$route.params.id)
    },
  },
}
</script>
